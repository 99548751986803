import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import ScrollspyNav from 'react-scrollspy-nav';
import { AshItem } from '../../../modules/ash/components/ash-item';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AshGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_intro.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>Basic information about the Ash Echoes gacha game.</h2>
          <p>
            Last updated: <strong>06/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                Ash Echoes is an RTS (Real Time Strategy) game developed by
                Aurogon Shanghai and published on Global servers by Neocraft. It
                features a sci-fi setting with a varied cast of characters who
                come from different worlds, after a cataclysm known as the
                “Skyrift Incident” begins to tear reality apart (literally)
              </p>
              <p>
                It’s very difficult to compare it to other gachas, as there
                isn’t anything quite like it at the market right now. However,
                you can probably think of “Divinity Original Sin 2” for its
                Elemental Reactions and Environmental interactions, as well as
                its strategical aspect. Another game that may have some
                semblance with it is “13 Sentinels”. Among gachas, enjoyers of
                Tower Defense titles like “Arknights” and “Path to Nowhere” may
                have a good time with Ash Echoes.
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Setting" />
              <StaticImage
                src="../../../images/ash/generic/intro_1.webp"
                alt="Guides"
              />
              <p>
                Stories of parallel worlds and strange substances with the power
                to change the environment and people around them are nothing
                new. From ancient mythology, to tales like H.P. Lovecraft's “The
                Colour Out of Space” (1927), to modern depictions of multiverse
                and Isekai across all types of media, modern audiences are more
                than familiar with this kind of premise.
              </p>
              <p>
                Ash Echoes starts from a common place, with the “Skyrift
                Incident” tearing down the walls between realities and exposing
                the world of Senlo to a substance that can bring both power and
                disgrace. It is from right then in the Prologue that Ash Echoes
                approaches this theme from a different perspective: What would
                you do if one day you stumbled upon a reality that looks quite
                similar to your own and yet isn't the same? What would you do if
                instead you fell on an entirely different world? Would you try
                to adapt and build a new life? Would you try to find a way to
                return home?
              </p>
              <p>
                From that point onwards, a tapestry of stories with endless
                possibilities begins to write itself. As the Chief Officer of
                S.E.E.D., we're introduced to people from all walks of life and
                locations affected in every way imaginable.
              </p>
              <p>
                This won't be a simple path of investigating a strange incident.
                In an almost kaleidoscope-esque format, as players, we're
                invited to see reality from a different lens in each story, and
                from a different point of view from each character.
              </p>
              <p>
                In this ever-expanding setting, bound together and streamlined
                by a common starting point, a journey unfolds. It's up not only
                to you, the “main character”, but to everyone involved to adapt
                and rebuild the fabric of reality, or let it fall apart into
                ashes that will echo across time, until the cycle begins anew.
              </p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="avB83U61idI" />
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay" />
              <StaticImage
                src="../../../images/ash/generic/intro_2.webp"
                alt="Guides"
              />
              <p>
                As already mentioned, Ash Echoes is an RTS, with bits of Tower
                Defense. In most stages, you will have a Terminal that not only
                you have to protect, but who also will benefit your team with
                certain boons and has a set of skills that you can use. Unlike a
                standard Tower Defense, enemies won't spawn and make a
                predefined route towards the Terminal, with the main focus being
                on your team versus the enemies.
              </p>
              <p>
                In most game modes, you can deploy up to four Echomancers on the
                field at once, and have four more as “backup”. You can switch
                characters between field ones and backup at any time (as long as
                the backup character isn't on cooldown). Each Echomancer also
                has a limited number of uses on their Active skills, which adds
                an extra layer of strategy to the gameplay, as you have to
                carefully plan when and how to use each skill.
              </p>
              <StaticImage
                src="../../../images/ash/generic/intro_0.webp"
                alt="Guides"
              />
              <p>
                Another core feature of the game are the Elemental Reactions.
                They can be triggered by creating zones of a specific element
                and mixing in another, or, in some cases, by enchanting an
                Echomancer's attack with another element.{' '}
              </p>
              <p>
                The third core aspect of gameplay is the Environment. Each stage
                has its own weather, which can affect how your elemental
                reactions will play. Some of them have special mechanics that
                you can interact with in order to generate elemental zones, blow
                away enemies or poisonous mist, stun enemies under a spotlight,
                swallow them on a quicksand trap, and so on. Each new patch and
                event introduces novel mechanics to add a fresh spin to the
                gameplay.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Classes and Elements" />
              <StaticImage
                src="../../../images/ash/generic/intro_4.webp"
                alt="Guides"
              />
              <p>
                The core structure of Ash Echoes is built upon five stats, seven
                classes and seven elements.
              </p>
              <h6>Stats</h6>
              <ul>
                <li>
                  <AshItem name="ATK" /> - Attack. Affects the Echomancer’s DMG
                  Output. The actual ATK stat is affected by Engraving ATK and
                  the Echomancer’s ATK Bonus.
                </li>
                <li>
                  <AshItem name="MST" /> - Mastery. Increases the final damage
                  dealt during battle. Additionally, for Bulwarks and Vanguards,
                  it affects the Max Block and Block Strength. For Echomancers
                  capable of healing, it affects the healing power.
                </li>
                <li>
                  <AshItem name="DEF" /> - Defense. Determines the DR (Damage
                  Reduction Rate).
                </li>
                <li>
                  <AshItem name="VIT" /> - Vitality. Affects the Echomancer’s
                  Max HP and Incoming Healing Bonus in battle.
                </li>
                <li>
                  <AshItem name="TRM" /> - Terminal. This stat affects the
                  Terminal’s HP, Energy, and affects several other mechanics
                  in-game, such as Corrosion Blast or the strength of enemies
                  during Nexus Encounters.
                </li>
              </ul>
              <h6>Classes</h6>
              <ul>
                <li>
                  <AshItem name="Bulwark" /> - A tank in its purest sense,
                  Bulwarks naturally draw the attention of enemies towards
                  themselves. They naturally have the highest defensive stats
                  and a Block Gauge.
                </li>
                <li>
                  <AshItem name="Vanguard" /> - Vanguards are versatile
                  frontline options who can adapt their skills on the fly,
                  having both Attack and Defense modes.
                </li>
                <li>
                  <AshItem name="Striker" /> - Strikers are specialists in
                  breaking Block Gauges and dealing with Elite Enemies and
                  Bosses.
                </li>
                <li>
                  <AshItem name="Skirmisher" /> - Skirmishers have high mobility
                  and burst capabilities, being able to cast their active skill
                  twice in a row in order to trigger a combo. They also
                  prioritize dealing with Airborne enemies.
                </li>
                <li>
                  <AshItem name="Ranger" /> - Rangers are masters of long
                  battles, often having mean to refresh their skill uses or have
                  more of them. As a damage-focused class, they can also boast
                  high burst damage or high skill range.
                </li>
                <li>
                  <AshItem name="Tactician" /> - Tacticians are enablers by
                  nature, and have a wide range of option to help the main DPS
                  of the team shine. Buffs, debuffs, CC, elemental zones, they
                  can have all sorts of utility, with each Tactician being
                  unique.
                </li>
                <li>
                  <AshItem name="Support" /> - As expected from the name,
                  Supports focus on aiding the team, They can bring healing,
                  buffs, shields and other utilities.
                </li>
              </ul>
              <h6>Elements</h6>
              <p>
                There are seven elements in total: Fire, Lightning, Water, Ice,
                Wind, Physical and Corrosion. Out of these, only the last three
                do not participate in elemental reactions, having their own
                mechanics instead.
              </p>
              <ul>
                <li>
                  <AshItem name="Fire" /> - Certain Echomancers can generate
                  Fire Zones. In [Dry Environments], Fire DMG also has a chance
                  to generate Fire Zones, and they last longer there than in
                  [Wet Environment]. Enemies inside a Fire Zone take Fire DMG
                  equal to 1% of their current HP per second, with the damage
                  capped at the value of the TRM stat.
                </li>
                <li>
                  <AshItem name="Lightning" /> - Can trigger Elemental Reactions
                  (Explosion, Electroconduct, Electrolyzed) but do not generate
                  elemental zones.
                </li>
                <li>
                  <AshItem name="Water" /> - Certain Echomancers can generate
                  Water Zones. In [Wet Environments], Water Zones last longer
                  and can even expand, while in [Dry Environments], they dry out
                  faster. Enemies inside a Water Zone take 10% more Water DMG.
                </li>
                <li>
                  <AshItem name="Ice" /> - Ice Zones can be generated by certain
                  Echomancer skills or by dealing Ice DMG to enemies inside a
                  Water Zone. Ice Zones are significantly easier to generate and
                  keep in [Wet Environments]. Enemies inside an Ice Zone will be
                  Frozen. Ice DMG on frozen enemies has a chance to trigger
                  [Frostbreak], killing them instantly.
                </li>
                <li>
                  <AshItem name="Wind" /> - Wind does not trigger elemental
                  reactions, but it can blow away enemies, clear up poisonous
                  mist, and deals additional damage to airborne enemies.
                </li>
                <li>
                  <AshItem name="Physical" /> - Physical does not trigger
                  elemental reactions, but can benefit from reaction bonuses,
                  such as the Damage Reduction received from Vaporization or the
                  extra damage dealt by Melt.
                </li>
                <li>
                  <AshItem name="Corrosion" /> - Whenever one or more Corrosion
                  Echomancers are on the field, a Corrosion Accumulation bar
                  will appear on the top right of the screen. At 100 Corrosion
                  Accumulation, a Corrosion Blast will occur, damaging all
                  enemies in the map based on their current HP percentage.
                  Corrosion scales with TRM and its bonus can be further
                  enhanced once TRM exceeds 1000 points.
                </li>
              </ul>
              <StaticImage
                src="../../../images/ash/generic/intro_5.webp"
                alt="Guides"
              />
              <h6>Elemental Reactions</h6>
              <ul>
                <li>
                  <strong>[Vaporized]</strong>: <AshItem name="Fire" /> +{' '}
                  <AshItem name="Water" />. Generates a Vaporized zone. Allied
                  Echomancers take 50% less damage while inside this zone.
                </li>
                <li>
                  <strong>[Explosion]</strong>: <AshItem name="Fire" /> +{' '}
                  <AshItem name="Lightning" />. When a target inside a Fire Zone
                  takes Lightning DMG, it receives additional DMG based on the
                  ATK and MST of the unit who triggered it.
                </li>
                <li>
                  <strong>[Melt]</strong>: <AshItem name="Fire" /> +{' '}
                  <AshItem name="Ice" />. When a target inside a Fire Zone takes
                  Ice DMG, or when a target inside an Ice Zone takes Fire DMG,
                  it triggers [Melt]. Each instance of Melt reduces the target’s
                  Basic Damage Reduction by 5%, up to 60%.
                </li>
                <li>
                  <strong>[Electroconduct]</strong>:{' '}
                  <AshItem name="Lightning" /> + <AshItem name="Water" />. When
                  a target inside a Water Zone takes Lightning DMG, it triggers
                  [Electroconduct]. Enemies affected by [Paralyzed] are unable
                  to take actions. While auto-attacks will only Paralyze the
                  main target, Lightning skills will make the effect spread and
                  affect all enemies within the Water Zone.
                </li>
                <li>
                  <strong>[Electrolyzed]</strong>: <AshItem name="Lightning" />{' '}
                  + <AshItem name="Ice" />. When a target inside an Ice Zone
                  takes Lightning DMG, it triggers [Electrolyzed]. This effect
                  reduces the ATK of the enemies within the zone by 33%,
                  stacking up to 3 times (less effective against Elite and Boss
                  enemies).
                </li>
                <li>
                  <strong>[Frozen]</strong>: <AshItem name="Water" /> +{' '}
                  <AshItem name="Ice" />. When a target inside a Water Zone
                  takes Ice DMG, or when a target inside an Ice Zone takes Water
                  DMG, it triggers [Freeze]. Frozen enemies cannot act.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Game Modes" />
              <StaticImage
                src="../../../images/ash/generic/intro_3.webp"
                alt="Guides"
              />
              <p>
                From players who just want to relax to those looking for a
                challenge, Ash Echoes has something for everyone.
              </p>
              <p>
                Main Story and Events are already a staple on gacha games. Here,
                we also have a Roguelike mode (Echoing Nexus), two types of
                raids (Joint Training and Simultaneous Raid), mini-games both to
                pass time and to increase the bond and knowledge with your
                favorite characters, a guild mode (Clubs) with periodic
                challenges, among others.
              </p>
              <p>
                <strong>Ash Echoes does not have PvP</strong>. It does have
                Leaderboards for its game modes. However, the Leaderboards don't
                give any rewards aside from badges to display on your profile
                and bragging rights. Furthermore, the game does feature Guilds
                (referred here as “Clubs”), where you can share Echomancers and
                Memory Traces with friends, and enjoy extra resources from the
                Guild shop and occasional Challenge.
              </p>
              <p>
                For more information about game modes, check out our dedicated
                coverage guide of them:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Game Modes"
                  link="/ash-echoes/guides/game-modes"
                  image={
                    <StaticImage
                      src="../../../images/ash/categories/category_modes.webp"
                      alt="Game Modes"
                    />
                  }
                />
              </Row>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Official images" />
              <Row xs={1} xl={2}>
                <Col>
                  <StaticImage
                    src="../../../images/ash/promo.webp"
                    alt="Guides"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../../../images/ash/promo2.webp"
                    alt="Guides"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../../../images/ash/promo3.webp"
                    alt="Guides"
                  />
                </Col>
                <Col>
                  <StaticImage
                    src="../../../images/ash/promo4.webp"
                    alt="Guides"
                  />
                </Col>
              </Row>
              <SectionHeader title="Trailer" />
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="1F92bTGP49o" />
                </Col>
              </Row>
              <SectionHeader title="Videos" />
              <p>If you want to see the gameplay check the videos below:</p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="PLkFVB91pyU" />
                </Col>
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="5WwDtTzdYMo" />
                </Col>
              </Row>
              <SectionHeader title="Official links" />
              <ul>
                <li>
                  Official Website:{' '}
                  <a
                    href="https://ae.neocraftstudio.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  Official Discord:{' '}
                  <a
                    href="https://discord.gg/ashechoesofficial"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  Official YouTube:{' '}
                  <a
                    href="https://www.youtube.com/@AshEchoes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  Official Twitter:{' '}
                  <a
                    href="https://x.com/AshEchoes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  Google Play Store:{' '}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.emagroups.ash"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  Apple Store:{' '}
                  <a
                    href="https://apps.apple.com/us/app/ash-echoes-global/id6484504004?mt=8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
                <li>
                  STEAM:{' '}
                  <a
                    href="https://store.steampowered.com/app/3068700/ASH_ECHOES/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      width="18"
                      icon={faArrowUpRightFromSquare}
                    />{' '}
                    Open in new window
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Setting</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay</a>
                </li>
                <li>
                  <a href="#section-3">Classes and Elements</a>
                </li>
                <li>
                  <a href="#section-4">Game Modes</a>
                </li>
                <li>
                  <a href="#section-5">Official links</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Ash Echoes | Prydwen Institute"
    description="Basic information about the Ash Echoes gacha game."
    game="ash"
  />
);
